
/**
 * Icon Source for IconsBase: IconSubscribe-57x50
 * Size: 57x50
 * @category Icons
 * @subcategory Icons Source
 *
 * @component
 */
export default {
  name: 'IconSubscribe-57x50',
};
